<template>
  <div>
    <banner
      title="Detil Informasi Pembinaan"
      :breadcrumbs="breadcrumbs"
      :icon="require('@/assets/home/decoration/penilaian.svg')"
    />

    <section v-if="$store.state.loadPage" id="content" class="mt-4">
      <div class="container">
        <div class="loading text-center">
          <img src="@/assets/loading-2.gif" alt="" class="img-fluid" />
        </div>
      </div>
    </section>

    <section v-else id="content" class="mt-4">
      <div class="container">
        <div class="card border-0">
          <div class="row">
            <div
              class="col-lg-7 overflow-hidden position-relative shadow-sm"
              style="border-radius:0.75rem;"
            >
              <img :src="policy.cover" class="w-100" alt="" />
              <div
                class="position-absolute"
                style="inset:0;"
              ></div>
            </div>
            <div class="col-lg-5">
              <div
                class="p-3"
                style="background: #EEF4FF;border-radius:0.75rem;"
              >
                <span class="bg-secondary rounded-pill px-2 py-1 text-white">
                  {{ convertDateTitle }}
                </span>
                <p class="mt-3 h4 font-weight-bold">{{ policy.title }}</p>
                <h4 class="text-muted h6">{{ policy.subtitle }}</h4>
                <div class="d-flex">
                  <div class="d-flex align-items-center">
                    <svg-icon name="check-circle" />
                    <span class="pl-2">
                      <span class="font-weight-bold h6">
                        {{ policy.registered }}
                      </span>
                      <span class="text-muted">orang telah mendaftar</span>
                    </span>
                  </div>
                  <div class="d-flex align-items-center pl-4">
                    <svg-icon name="user-three" />
                    <span class="pl-2">
                      <span class="font-weight-bold h6">
                        {{ remainingQuota }}
                      </span>
                      <span class="text-muted">Kuota Tersisa</span>
                    </span>
                  </div>
                </div>
                <div style="margin-top: 100px;gap: 15px;" class="d-flex">
                  <button
                    v-if="
                      policy.status == 'publish' &&
                        userRegisteredStatus.status == 'pending'
                    "
                    class="btn btn-block btn-secondary mt-3"
                    disabled
                  >
                    Menunggu Konfirmasi
                  </button>
                  <!-- <button
                    v-if="
                      policy.status == 'publish' &&
                        userRegisteredStatus.status == 'approved'
                    "
                    class="btn btn-block btn-secondary mt-3"
                    data-toggle="modal"
                    data-target="#tiketModal"
                  >
                    Lihat Tiket
                  </button> -->
                  <button
                    v-if="policy.status == 'draft' || policy.status == ''"
                    class="btn btn-block mt-3"
                    style="background-color: var(--grey-500);color: white"
                    disabled
                  >
                    Pendaftaran Ditutup
                  </button>
                  <button
                    v-if="policy.status == 'publish' && (userRegisteredStatus.status == undefined || userRegisteredStatus.status == 'failed')"
                    class="btn btn-block btn-secondary mt-3"
                    data-toggle="modal"
                    :data-target="modalForm"
                    :disabled="false"
                  >
                    Daftar Event
                  </button>
                  <button
                    class="btn btn-block btn-primary mt-3"
                    data-toggle="modal"
                    data-target="#checkKepesertaan"
                  >
                    Cek Kepesertaan
                  </button>
                </div>
                <div v-if="policy.status == 'finish' && (userRegisteredStatus.status == 'attended')">
                  <a
                      class="btn btn-block btn-success mt-3"
                      :href="`https://app.buku.kemdikbud.go.id/sertifikat/${userRegisteredStatus.ticketcode}`"
                      target="_blank" 
                    >
                      Lihat Sertifikat
                  </a>
                  <div class="mt-3">Catatan : Sertifikat saat ini masih tertulis tidak ditemukan. Buka kembali tautan sertifikat ketika acara sudah selesai, atau sesuai arahan panitia.</div>
                </div>
                <div class="d-flex">
                  <button
                      v-if="policy.status == 'finish' && (userRegisteredStatus.status == 'approved')"
                      class="btn btn-block btn-success mt-3"
                      @click="attend(userRegisteredStatus.ticketcode)"
                    >
                      Hadir
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-7">
          <div class=" pt-3">
            <h4 class="font-weight-bold">Tentang Event Ini</h4>
            <p v-html="policy.description"></p>
          </div>

          <div class="mb-4">
            <h4 class="font-weight-bold">Tanggal dan Waktu Pendaftaran</h4>
            <div class="d-flex">
              <div class="icon-wrapper">
                <svg-icon name="calendar-check" />
              </div>
              <div>
                <div
                  class="h6 m-0"
                  v-if="policy.start.substr(0, 10) == policy.end.substr(0, 10)"
                >
                  {{ convertStartDate }}
                </div>
                <div v-else class="h6 m-0">
                  {{ convertStartDate }} - {{ convertEndDate }}
                </div>
                <span class="text-muted"
                  >Pukul {{ policy.start.substr(10) }} - Selesai</span
                >
              </div>
            </div>
          </div>
          <div class="mb-4 ">
            <h4 class="font-weight-bold">Lokasi</h4>
            <div class="d-flex">
              <div class="icon-wrapper">
                <svg-icon name="map-pin" />
              </div>
              <div>
                <div class="h6 m-0">
                  {{ policy.location || "Online (Daring)" }}
                </div>
                <div v-if="policy.location_address != ''" class="text-muted">
                  {{ policy.location_address }}
                </div>
                <a
                  v-if="policy.maps_url != ''"
                  target="_blank"
                  :href="policy.maps_url"
                  >Lihat map</a
                >
              </div>
            </div>
            <!-- <a target="_blank" :href="policy.maps_url">Lihat map</a> -->
          </div>
          <div
            v-if="policy.requirement != '' && policy.requirement != null"
            class="mb-4"
          >
            <h5>Persyaratan Peserta</h5>
            <p v-html="policy.requirement"></p>
          </div>
        </div>
      </div>

      <!-- Register Modal Ilustrator -->
      <div
        class="modal fade"
        id="registerModalIlustrator"
        tabindex="-1"
        role="dialog"
        aria-labelledby="registerModalIlustratorLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-lg" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title text-center" id="registerModalIlustratorLabel">
                Konfirmasi Pendaftaran
              </h5>
              <button
                @click="$store.state.messageRegisterSuccess = ''"
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <form
              v-if="!successRegistered"
              @submit.prevent="postRegisterTraining()"
            >
              <div class="modal-body">
                <div class="mb-3">
                  <span class="font-weight-bold">Judul Event :</span> <br />
                  <span>{{ policy.title }}</span>
                </div>

                <div class="form-group">
                  <label for="namaPeserta" class="form-label">Nama Peserta</label
                  >
                  <input
                    type="text"
                    v-model="register.name"
                    id="namaPeserta"
                    class="form-control"
                    required
                  />
                </div>
                <div class="form-group">
                  <label for="email" class="form-label">Alamat Email</label>
                  <input
                    type="email"
                    v-model="register.email"
                    id="email"
                    class="form-control"
                    required
                  />
                </div>
                <div class="form-group">
                  <label for="phone" class="form-label">Nomor Whatsapp</label>
                  <input
                    type="number"
                    v-model="register.phone"
                    id="phone"
                    class="form-control"
                    required
                  />
                </div>
                <div class="form-group">
                  <label for="address" class="form-label">Alamat</label>
                  <input
                    type="text"
                    v-model="register.address"
                    id="address"
                    class="form-control"
                    required
                  />
                </div>
                <div class="form-group">
                  <label for="jenisBuku" class="form-label">Pilih kategori buku yang diminati untuk diilustrasikan.</label>
                  <select
                    id="role"
                    v-model="register.role"
                    class="form-control"
                    required
                  >
                    <option value="Cerita Bergambar (Fiksi)">Cerita Bergambar (Fiksi)</option>
                    <option value="Nonfiksi Naratif">Nonfiksi Naratif</option>
                    <option value="Komik">Komik</option>
                    <option value="Novel">Novel</option>
                    <option value="Puisi (Kumpulan Puisi)">Puisi (Kumpulan Puisi)</option>
                  </select>
                </div>
                <div class="form-group">
                  <label for="role" class="form-label">Difabel Status</label>
                  <div class="form-check">
                    <input
                      v-model="register.difable_status"
                      value="yes"
                      class="form-check-input"
                      type="checkbox"
                      id="difable_status"
                      @click="setDifableStatus()"
                    />
                    <label class="form-check-label" for="difable_status">
                      Ya
                    </label>
                  </div>
                </div>
                <div class="form-group">
                  <label for="profile_picture" class="form-label">
                    Foto Profil
                  </label>
                  <small class="d-block">Silakan unggah foto profil terbaik dalam format jpg atau png (maksimal 10mb).</small>
                  <small
                    v-if="message.profile_picture.error != ''"
                    class="text-danger d-block"
                  >
                    {{ message.profile_picture.error }}
                  </small
                  >
                  <input
                    :type="rules.profile_picture.form"
                    id="profile_picture"
                    class="form-control"
                    @change="selectFilePDF($event, 'profile_picture')"
                    required
                  />
                  <input type="hidden" v-model="fileUploaded.profile_picture" />
                  <div class="my-2">
                    <div class="row">
                      <div class="col-md-4">
                        <button
                          v-if="
                            !loading.profile_picture && !message.profile_picture.uploaded
                          "
                          @click="uploadFilePDF('profile_picture')"
                          type="button"
                          class="btn btn-sm btn-primary btn-block"
                        >
                          Upload
                        </button>
                        <div v-if="message.profile_picture.uploaded" class="d-flex">
                          <button
                            type="button"
                            class="btn btn-secondary btn-sm"
                          >
                            Uploaded
                          </button>
                          <span class="mt-1 ml-2">{{ fileUploaded.profile_picture }}</span>
                        </div>
                        <button
                          type="button"
                          v-if="loading.profile_picture && !message.profile_picture.uploaded"
                          class="btn btn-sm btn-primary btn-block"
                        >
                          <span class="spinner-border spinner-border-sm"></span>
                          Sedang upload..
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <label for="profile_picture" class="form-label">
                    Biodata
                  </label>
                  <small class="d-block">Silakan unggah berkas Biodata (CV)  perorangan dalam format pdf (maksimal 10mb)</small>
                  <small
                    v-if="message.portfolio.error != ''"
                    class="text-danger d-block"
                  >
                    {{ message.portfolio.error }}
                  </small
                  >
                  <input
                    :type="rules.portfolio.form"
                    id="portfolio"
                    class="form-control"
                    @change="selectFilePDF($event, 'portfolio')"
                    required
                  />
                  <input type="hidden" v-model="fileUploaded.portfolio" />
                  <div class="my-2">
                    <div class="row">
                      <div class="col-md-4">
                        <button
                          v-if="
                            !loading.portfolio && !message.portfolio.uploaded
                          "
                          @click="uploadFilePDF('portfolio')"
                          type="button"
                          class="btn btn-sm btn-primary btn-block"
                        >
                          Upload
                        </button>
                        <div v-if="message.portfolio.uploaded" class="d-flex">
                          <button
                            type="button"
                            class="btn btn-secondary btn-sm"
                          >
                            Uploaded
                          </button>
                          <span class="mt-1 ml-2">{{ fileUploaded.portfolio }}</span>
                        </div>
                        <button
                          type="button"
                          v-if="loading.portfolio && !message.portfolio.uploaded"
                          class="btn btn-sm btn-primary btn-block"
                        >
                          <span class="spinner-border spinner-border-sm"></span>
                          Sedang upload..
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <label for="lampiran" class="form-label">
                    Karya
                  </label>
                  <small class="d-block">Silakan unggah berkas sampel karya dalam format pdf atau zip (maksimal 10mb)</small>
                  <small
                    v-if="message.lampiran.error != ''"
                    class="text-danger d-block"
                  >
                    {{ message.lampiran.error }}
                  </small>
                  <input
                    :type="rules.lampiran.form"
                    id="lampiran"
                    class="form-control"
                    @change="selectFilePDF($event, 'lampiran')"
                    required
                  />
                  <input type="hidden" v-model="fileUploaded.lampiran" />
                  <div class="my-2">
                    <div class="row">
                      <div class="col-md-4">
                        <button
                          v-if="!loading.lampiran && !message.lampiran.uploaded"
                          @click="uploadFilePDF('lampiran')"
                          type="button"
                          class="btn btn-sm btn-primary btn-block"
                        >
                          Upload
                        </button>
                        <div v-if="message.lampiran.uploaded" class="d-flex">
                          <button
                            type="button"
                            class="btn btn-secondary btn-sm"
                          >
                            Uploaded
                          </button>
                          <span class="mt-1 ml-2">{{ fileUploaded.lampiran }}</span>
                        </div>
                        <button
                          type="button"
                          v-if="loading.lampiran && !message.lampiran.uploaded"
                          class="btn btn-sm btn-primary btn-block"
                        >
                          <span class="spinner-border spinner-border-sm"></span>
                          Sedang upload..
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="small"><b>Penting : </b>Jangan lupa klik tombol upload berkas sebelum daftar</div>
              </div>
              <div class="modal-footer">
                <button
                  v-if="!$store.state.loadProcess"
                  type="submit"
                  class="btn btn-primary"
                >
                  Daftar
                </button>
                <button v-else type="button" class="btn btn-primary">
                  Sedang diproses..
                </button>
              </div>
            </form>
            <div v-else class="modal-body">
              <div class="alert alert-success">
                Selamat, pendaftaran berhasil. Selanjutnya mohon untuk menunggu
                proses verifikasi.
              </div>

              <table class="table table-bordered">
                <tr>
                  <td width="160">Nama</td>
                  <td width="20">:</td>
                  <td>{{ dataRegistered.name }}</td>
                </tr>
                <tr>
                  <td>Nomor Tiket</td>
                  <td>:</td>
                  <td>{{ dataRegistered.ticketcode }}</td>
                </tr>
                <tr>
                  <td>Tanggal Daftar</td>
                  <td>:</td>
                  <td>{{ registeredDate }}</td>
                </tr>
                <tr>
                  <td>Kode QR</td>
                  <td>:</td>
                  <td>
                    <img :src="qrCodeRegistered" alt="" />
                  </td>
                </tr>
              </table>

              <p>
                <span style="color:red;">
                  Note : Mohon untuk menyimpan nomor tiket pendaftaran untuk
                  keperluan verifikasi. Bukti pendaftaran acara juga sudah kami
                  kirimkan ke email
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <!-- End Register Modal Ilustrator -->
      
      <!-- Register Modal Penulis -->
      <div
        class="modal fade"
        id="registerModalPenulis"
        tabindex="-1"
        role="dialog"
        aria-labelledby="registerModalPenulisLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-lg" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title text-center" id="registerModalPenulisLabel">
                Konfirmasi Pendaftaran
              </h5>
              <button
                @click="$store.state.messageRegisterSuccess = ''"
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <form
              v-if="!successRegistered"
              @submit.prevent="postRegisterTraining()"
            >
              <div v-if="!readTerms" class="modal-body">
                <p>Silahkan untuk baca persayaratan terlebih dahulu pada link dibawah ini :</p>
                <a class="d-block" @click="openLink = true" href="https://docs.google.com/document/d/1V4D4lxYVJD7RaxakTeyrKwGMIAuo7PEBw8eOk4pne1o/edit?usp=sharing" target="_blank">Baca Persyaratan <i class="fa fa-external-link"></i></a>
                <button @click="readTerms = true" v-show="openLink" class="btn btn-sm btn-secondary mt-3">Saya menyetujui persyaratan tersebut</button>
              </div>
              <div v-else>
                <div class="modal-body">
                  <div class="mb-3">
                    <span class="font-weight-bold">Judul Event :</span> <br />
                    <span>{{ policy.title }}</span>
                  </div>
  
                  <div class="form-group">
                    <label for="namaPeserta" class="form-label">Nama Peserta</label
                    >
                    <input
                      type="text"
                      v-model="register.name"
                      id="namaPeserta"
                      class="form-control"
                      required
                    />
                  </div>
                  <div class="form-group">
                    <label for="email" class="form-label">Alamat Email</label>
                    <input
                      type="email"
                      v-model="register.email"
                      id="email"
                      class="form-control"
                      required
                    />
                  </div>
                  <div class="form-group">
                    <label for="phone" class="form-label">Nomor Whatsapp</label>
                    <input
                      type="number"
                      v-model="register.phone"
                      id="phone"
                      class="form-control"
                      required
                    />
                  </div>
                  <div class="form-group">
                    <label for="address" class="form-label">Alamat</label>
                    <input
                      type="text"
                      v-model="register.address"
                      id="address"
                      class="form-control"
                      required
                    />
                  </div>
                  <div class="form-group">
                    <label for="jenisBuku" class="form-label">Kategori Workshop</label>
                    <select
                      id="role"
                      @change="($event) => setTypeBook($event.target.value)"
                      class="form-control"
                      required
                    >
                      <option value="Cerita Bergambar (Fiksi)">Cerita Bergambar (Fiksi)</option>
                      <option value="Nonfiksi Naratif">Nonfiksi Naratif</option>
                      <option value="Puisi (Kumpulan Puisi)">Puisi (Kumpulan Puisi)</option>
                      <option value="Komik">Komik</option>
                      <option value="Novel">Novel</option>
                    </select>
                  </div>
                  <div class="form-group">
                    <label for="role_detail" class="form-label">Jenjang</label>
                    <select
                      id="role_detail"
                      @change="($event) => setLevelBook($event.target.value)"
                      class="form-control"
                      required
                    >
                      <template v-if="register.role == 'Cerita Bergambar (Fiksi)'">
                        <option value="A" selected>A</option>
                        <option value="B1">B1</option>
                        <option value="B2">B2</option>
                        <option value="B3">B3</option>
                        <option value="C">C</option>
                      </template>
                      <template v-if="register.role == 'Nonfiksi Naratif'">
                        <option value="A" selected>A</option>
                        <option value="B1">B1</option>
                        <option value="B2">B2</option>
                        <option value="B3">B3</option>
                        <option value="C">C</option>
                        <option value="D">D</option>
                      </template>
                      <template v-if="register.role == 'Puisi (Kumpulan Puisi)'">
                        <option value="C" selected>C</option>
                        <option value="E">E</option>
                      </template>
                      <template v-if="register.role == 'Komik'">
                        <option value="D" selected>D</option>
                        <option value="E">E</option>
                      </template>
                      <template v-if="register.role == 'Novel'">
                        <option value="D" selected>D</option>
                        <option value="E">E</option>
                      </template>
                    </select>
                    <small>Penting! Baca tentang pedoman perjenjangan disini <a href="https://buku.kemdikbud.go.id/s/PedomanPerjenjangan" target="_blank">https://buku.kemdikbud.go.id/s/PedomanPerjenjangan</a> dan Perkabadan disini <a target="_blank" href="http://ringkas.kemdikbud.go.id/AturanPerjenjangan">http://ringkas.kemdikbud.go.id/AturanPerjenjangan</a></small>
                  </div>
                  <div class="form-group">
                    <label for="tema" class="form-label">Tema</label>
                    <select @change="($event) => setTheme($event.target.value)" id="tema" class="form-control" required>

                      <!-- Cerita Bergambar (Fiksi) -->
                      <template v-if="register.role == 'Cerita Bergambar (Fiksi)'">
                        <template v-if="register.role_detail == 'A'">
                          <option value="Literasi Digital" selected>Literasi Digital</option>
                          <option value="Literasi Finansial">Literasi Finansial</option>
                          <option value="Nutrisi dan Kebugaran">Nutrisi dan Kebugaran</option>
                        </template>
                        <template v-if="register.role_detail == 'B1'">
                          <option value="Literasi Digital" selected>Literasi Digital</option>
                          <option value="Literasi Finansial">Literasi Finansial</option>
                          <option value="Antiperundungan">Antiperundungan</option>
                        </template>
                        <template v-if="register.role_detail == 'B2'">
                          <option value="Literasi Digital" selected>Literasi Digital</option>
                          <option value="Nutrisi dan Kebugaran">Nutrisi dan Kebugaran</option>
                          <option value="Antiperundungan">Antiperundungan</option>
                        </template>
                        <template v-if="register.role_detail == 'B3'">
                          <option value="Literasi Finansial" selected>Literasi Finansial</option>
                          <option value="Nutrisi dan Kebugaran">Nutrisi dan Kebugaran</option>
                          <option value="Antiperundungan" selected>Antiperundungan</option>
                        </template>
                        <template v-if="register.role_detail == 'C'">
                          <option value="Literasi Digital" selected>Literasi Digital</option>
                          <option value="Literasi Finansial">Literasi Finansial</option>
                        </template>
                      </template>

                      <!-- Nonfiksi Naratif -->
                      <template v-if="register.role == 'Nonfiksi Naratif'">
                        <template v-if="register.role_detail == 'A'">
                          <option value="Antiperundungan" selected>Antiperundungan</option>
                        </template>
                        <template v-if="register.role_detail == 'B1'">
                          <option value="Nutrisi dan Kebugaran" selected>Nutrisi dan Kebugaran</option>
                        </template>
                        <template v-if="register.role_detail == 'B2'">
                          <option value="Literasi Finansial" selected>Literasi Finansial</option>
                        </template>
                        <template v-if="register.role_detail == 'B3'">
                          <option value="Literasi Digital" selected>Literasi Digital</option>
                        </template>
                        <template v-if="register.role_detail == 'C'">
                          <option value="Antiperundungan" selected>Antiperundungan</option>
                        </template>
                        <template v-if="register.role_detail == 'D'">
                          <option value="Literasi Digital" selected>Literasi Digital</option>
                        </template>
                      </template>

                      <!-- Puisi (Kumpulan Puisi) -->
                      <template v-if="register.role == 'Puisi (Kumpulan Puisi)'">
                        <template v-if="register.role_detail == 'C' || register.role_detail == 'E'">
                          <option value="Literasi Digital" selected>Literasi Digital</option>
                          <option value="Literasi Finansial">Literasi Finansial</option>
                          <option value="Nutrisi dan Kebugaran">Nutrisi dan Kebugaran</option>
                          <option value="Antiperundungan">Antiperundungan</option>
                          <option value="Kesadaran Lingkungan">Kesadaran Lingkungan</option>
                          <option value="Lain-lain">Lain-lain</option>
                        </template>
                      </template>

                      <!-- Komik -->
                      <template v-if="register.role == 'Komik'">
                        <template v-if="register.role_detail == 'D'">
                          <option value="Literasi Finansial" selected>Literasi Finansial</option>
                        </template>
                        <template v-if="register.role_detail == 'E'">
                          <option value="Antiperundungan">Antiperundungan</option>
                        </template>
                      </template>

                      <!-- Novel -->
                      <template v-if="register.role == 'Novel'">
                        <template v-if="register.role_detail == 'D'">
                          <option value="Nutrisi dan Kebugaran" selected>Nutrisi dan Kebugaran</option>
                          <option value="Antiperundungan">Antiperundungan</option>
                        </template>
                        <template v-if="register.role_detail == 'E'">
                          <option value="Literasi Digital" selected>Literasi Digital</option>
                        </template>
                      </template>

                    </select>
                  </div>
                  <div class="form-group">
                    <label for="note" class="form-label">Sinopsis (ringkasan cerita)</label>
                    <textarea v-model="register.note" id="note" rows="5" class="form-control" placeholder="Naskah / Buku Jadi" required></textarea>
                    <small>Masukan sesuai persyaratan yang berlaku</small>
                  </div>
                  <div class="form-group">
                    <label for="role" class="form-label">Difabel Status</label>
                    <div class="form-check">
                      <input
                        v-model="register.difable_status"
                        value="yes"
                        class="form-check-input"
                        type="checkbox"
                        id="difable_status"
                        @click="setDifableStatus()"
                      />
                      <label class="form-check-label" for="difable_status">
                        Ya
                      </label>
                    </div>
                  </div>
                  <div class="form-group">
                    <label for="profile_picture" class="form-label">
                      Foto Profil
                    </label>
                    <small class="d-block">Silakan unggah foto profil terbaik dalam format jpg atau png (maksimal 10mb).</small>
                    <small
                      v-if="message.profile_picture.error != ''"
                      class="text-danger d-block"
                    >
                      {{ message.profile_picture.error }}
                    </small
                    >
                    <input
                      :type="rules.profile_picture.form"
                      id="profile_picture"
                      class="form-control"
                      @change="selectFilePDF($event, 'profile_picture')"
                      required
                    />
                    <input type="hidden" v-model="fileUploaded.profile_picture" />
                    <div class="my-2">
                      <div class="row">
                        <div class="col-md-4">
                          <button
                            v-if="
                              !loading.profile_picture && !message.profile_picture.uploaded
                            "
                            @click="uploadFilePDF('profile_picture')"
                            type="button"
                            class="btn btn-sm btn-primary btn-block"
                          >
                            Upload
                          </button>
                          <div v-if="message.profile_picture.uploaded" class="d-flex">
                            <button
                              type="button"
                              class="btn btn-secondary btn-sm"
                            >
                              Uploaded
                            </button>
                            <span class="mt-1 ml-2">{{ fileUploaded.profile_picture }}</span>
                          </div>
                          <button
                            type="button"
                            v-if="loading.profile_picture && !message.profile_picture.uploaded"
                            class="btn btn-sm btn-primary btn-block"
                          >
                            <span class="spinner-border spinner-border-sm"></span>
                            Sedang upload..
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <label for="profile_picture" class="form-label">
                      Biodata
                    </label>
                    <small class="d-block">Silakan unggah berkas Biodata (CV)  perorangan atau tim lengkap dalam format pdf (maksimal 10mb)</small>
                    <small
                      v-if="message.portfolio.error != ''"
                      class="text-danger d-block"
                    >
                      {{ message.portfolio.error }}
                    </small
                    >
                    <input
                      :type="rules.portfolio.form"
                      id="portfolio"
                      class="form-control"
                      @change="selectFilePDF($event, 'portfolio')"
                      required
                    />
                    <input type="hidden" v-model="fileUploaded.portfolio" />
                    <div class="my-2">
                      <div class="row">
                        <div class="col-md-4">
                          <button
                            v-if="
                              !loading.portfolio && !message.portfolio.uploaded
                            "
                            @click="uploadFilePDF('portfolio')"
                            type="button"
                            class="btn btn-sm btn-primary btn-block"
                          >
                            Upload
                          </button>
                          <div v-if="message.portfolio.uploaded" class="d-flex">
                            <button
                              type="button"
                              class="btn btn-secondary btn-sm"
                            >
                              Uploaded
                            </button>
                            <span class="mt-1 ml-2">{{ fileUploaded.portfolio }}</span>
                          </div>
                          <button
                            type="button"
                            v-if="loading.portfolio && !message.portfolio.uploaded"
                            class="btn btn-sm btn-primary btn-block"
                          >
                            <span class="spinner-border spinner-border-sm"></span>
                            Sedang upload..
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <label for="lampiran" class="form-label">
                      Karya
                    </label>
                    <small class="d-block">Silakan unggah sampel karya sesuai yang tercantum pada <a target="_blank" href="https://docs.google.com/document/d/1V4D4lxYVJD7RaxakTeyrKwGMIAuo7PEBw8eOk4pne1o/edit">persyaratan</a> dalam pdf atau zip (maksimal 10mb)</small>
                    <small
                      v-if="message.lampiran.error != ''"
                      class="text-danger d-block"
                    >
                      {{ message.lampiran.error }}
                    </small>
                    <input
                      :type="rules.lampiran.form"
                      id="lampiran"
                      class="form-control"
                      @change="selectFilePDF($event, 'lampiran')"
                      required
                    />
                    <input type="hidden" v-model="fileUploaded.lampiran" />
                    <div class="my-2">
                      <div class="row">
                        <div class="col-md-4">
                          <button
                            v-if="!loading.lampiran && !message.lampiran.uploaded"
                            @click="uploadFilePDF('lampiran')"
                            type="button"
                            class="btn btn-sm btn-primary btn-block"
                          >
                            Upload
                          </button>
                          <div v-if="message.lampiran.uploaded" class="d-flex">
                            <button
                              type="button"
                              class="btn btn-secondary btn-sm"
                            >
                              Uploaded
                            </button>
                            <span class="mt-1 ml-2">{{ fileUploaded.lampiran }}</span>
                          </div>
                          <button
                            type="button"
                            v-if="loading.lampiran && !message.lampiran.uploaded"
                            class="btn btn-sm btn-primary btn-block"
                          >
                            <span class="spinner-border spinner-border-sm"></span>
                            Sedang upload..
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="small"><b>Penting : </b>Jangan lupa klik tombol upload berkas sebelum daftar</div>
                </div>
                <div class="modal-footer">
                  <button
                    v-if="!$store.state.loadProcess"
                    type="submit"
                    class="btn btn-primary"
                  >
                    Daftar
                  </button>
                  <button v-else type="button" class="btn btn-primary">
                    Sedang diproses..
                  </button>
                </div>
              </div>
            </form>
            <div v-else class="modal-body">
              <div class="alert alert-success">
                Selamat, pendaftaran berhasil. Selanjutnya mohon untuk menunggu
                proses verifikasi.
              </div>

              <table class="table table-bordered">
                <tr>
                  <td width="160">Nama</td>
                  <td width="20">:</td>
                  <td>{{ dataRegistered.name }}</td>
                </tr>
                <tr>
                  <td>Nomor Tiket</td>
                  <td>:</td>
                  <td>{{ dataRegistered.ticketcode }}</td>
                </tr>
                <tr>
                  <td>Tanggal Daftar</td>
                  <td>:</td>
                  <td>{{ registeredDate }}</td>
                </tr>
                <tr>
                  <td>Kode QR</td>
                  <td>:</td>
                  <td>
                    <img :src="qrCodeRegistered" alt="" />
                  </td>
                </tr>
              </table>

              <p>
                <span style="color:red;">
                  Note : Mohon untuk menyimpan nomor tiket pendaftaran untuk
                  keperluan verifikasi. Bukti pendaftaran acara juga sudah kami
                  kirimkan ke email
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <!-- End Register Modal Penulis -->

      <!-- Register Modal Simple -->
      <div
        class="modal fade"
        id="registerModalSimple"
        tabindex="-1"
        role="dialog"
        aria-labelledby="registerModalSimpleLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-lg" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title text-center" id="registerModalSimpleLabel">
                Konfirmasi Pendaftaran
              </h5>
              <button
                @click="$store.state.messageRegisterSuccess = ''"
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div v-show="!isLoggedIn" class="alert alert-warning m-3">
              Silahkan <router-link to="/login" class="text-primary" data-dismiss="modal" aria-label="Close">Login</router-link> atau <router-link to="/register" class="text-primary" data-dismiss="modal" aria-label="Close">Daftar</router-link> akun terlebih dahulu
            </div>
            <form
              v-show="isLoggedIn"
              v-if="!successRegistered"
              @submit.prevent="postRegisterTraining()"
            >
              <div class="modal-body">
                <div class="mb-3">
                  <span class="font-weight-bold">Judul Event :</span> <br />
                  <span>{{ policy.title }}</span>
                </div>
                <div class="form-group">
                  <label for="namaPeserta" class="form-label">Nama Peserta</label
                  >
                  <input
                    type="text"
                    v-model="register.name"
                    id="namaPeserta"
                    class="form-control"
                    required
                  />
                </div>
                <div class="form-group">
                  <label for="email" class="form-label">Alamat Email</label>
                  <input
                    type="email"
                    v-model="register.email"
                    id="email"
                    class="form-control"
                    required
                  />
                </div>
                <div class="form-group">
                  <label for="phone" class="form-label">Nomor Whatsapp</label>
                  <input
                    type="number"
                    v-model="register.phone"
                    id="phone"
                    class="form-control"
                    required
                  />
                </div>
                
                <div class="modal-footer">
                  <button
                    v-if="!$store.state.loadProcess"
                    type="submit"
                    class="btn btn-primary"
                  >
                    Daftar
                  </button>
                  <button v-else type="button" class="btn btn-primary">
                    Sedang diproses..
                  </button>
                </div>
              </div>
            </form>
            <div v-else class="modal-body">
              <div class="alert alert-success">
                Selamat, pendaftaran berhasil. Selanjutnya mohon untuk menunggu
                proses verifikasi.
              </div>

              <!-- <table class="table table-bordered">
                <tr>
                  <td width="160">Nama</td>
                  <td width="20">:</td>
                  <td>{{ dataRegistered.name }}</td>
                </tr>
                <tr>
                  <td>Nomor Tiket</td>
                  <td>:</td>
                  <td>{{ dataRegistered.ticketcode }}</td>
                </tr>
                <tr>
                  <td>Tanggal Daftar</td>
                  <td>:</td>
                  <td>{{ registeredDate }}</td>
                </tr>
                <tr>
                  <td>Kode QR</td>
                  <td>:</td>
                  <td>
                    <img :src="qrCodeRegistered" alt="" />
                  </td>
                </tr>
              </table>

              <p>
                <span style="color:red;">
                  Note : Mohon untuk menyimpan nomor tiket pendaftaran untuk
                  keperluan verifikasi. Bukti pendaftaran acara juga sudah kami
                  kirimkan ke email
                </span>
              </p> -->
            </div>
          </div>
        </div>
      </div>
      <!-- End Register Modal Simple -->
      
      <!-- Register Modal -->
      <div
        class="modal fade"
        id="registerModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="registerModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-lg" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title text-center" id="registerModalLabel">
                Konfirmasi Pendaftaran
              </h5>
              <button
                @click="$store.state.messageRegisterSuccess = ''"
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <form
              v-if="!successRegistered"
              @submit.prevent="postRegisterTraining()"
            >
              <div class="modal-body">
                <div class="mb-3">
                  <span class="font-weight-bold">Judul Event :</span> <br />
                  <span>{{ policy.title }}</span>
                </div>

                <div class="form-group">
                  <label for="namaPeserta" class="form-label">Nama Peserta</label
                  >
                  <input
                    type="text"
                    v-model="register.name"
                    id="namaPeserta"
                    class="form-control"
                    required
                  />
                </div>
                <div class="form-group">
                  <label for="email" class="form-label">Alamat Email</label>
                  <input
                    type="email"
                    v-model="register.email"
                    id="email"
                    class="form-control"
                    required
                  />
                </div>
                <div class="form-group">
                  <label for="phone" class="form-label">Nomor Whatsapp</label>
                  <input
                    type="number"
                    v-model="register.phone"
                    id="phone"
                    class="form-control"
                    required
                  />
                </div>
                <div class="form-group">
                  <label for="address" class="form-label">Alamat</label>
                  <input
                    type="text"
                    v-model="register.address"
                    id="address"
                    class="form-control"
                    required
                  />
                </div>
                <div class="form-group">
                  <label for="role" class="form-label">Kategori Buku</label>
                  
                  <!-- Select category  -->
                  <div class="d-flex" style="gap: 15px;">
                    <div class="custom-control custom-radio">
                      <input v-model="category" @click="() => setDefaultCategory('Fiksi')" type="radio" id="fiksi" name="category" value="Fiksi" class="custom-control-input">
                      <label class="custom-control-label" for="fiksi">Fiksi</label>
                    </div>
                    <div class="custom-control custom-radio">
                      <input v-model="category" @click="() => setDefaultCategory('Non Fiksi')" type="radio" id="nonfiksi" name="category" value="Non Fiksi" class="custom-control-input">
                      <label class="custom-control-label" for="nonfiksi">Non Fiksi</label>
                    </div>
                  </div>

                  <select
                    id="role"
                    v-model="register.role"
                    class="form-control mt-3"
                    required
                  >
                    <template v-if="category == 'Fiksi'">
                      <option value="Buku Bergambar">Buku Bergambar</option>
                      <option value="Novel">Novel</option>
                      <option value="Novel Bergambar">Novel Bergambar</option>
                      <option value="Komik">Komik</option>
                      <option value="Antologi Cerpen">Antologi Cerpen</option>
                      <option value="Antologi Puisi">Antologi Puisi</option>
                      <option value="Novel Grafis">Novel Grafis</option>
                      <option value="Naskah Drama">Naskah Drama</option>
                      <option value="Naskah Lainnya">Naskah Lainnya</option>
                    </template>
                    <template v-if="category == 'Non Fiksi'">
                      <option value="Ensiklopedi">Ensiklopedi</option>
                      <option value="Buku Sains">Buku Sains</option>
                      <option value="Biografi">Biografi</option>
                      <option value="Pengembangan Diri">Pengembangan Diri</option>
                      <option value="Buku Bergambar">Buku Bergambar</option>
                      <option value="Naskah Lainnya">Naskah Lainnya</option>
                    </template>
                  </select>
                  <input v-show="register.role == 'Naskah Lainnya'" type="text" v-model="optionMore" class="form-control mt-3" placeholder="Masukan kategori">
                </div>
                <div class="form-group">
                  <label for="role_detail" class="form-label">Jenjang</label>
                  <select
                    id="role_detail"
                    v-model="register.role_detail"
                    class="form-control"
                    required
                  >
                    <option value="A">A</option>
                    <option value="B1">B1</option>
                    <option value="B2">B2</option>
                    <option value="B3">B3</option>
                    <option value="C">C</option>
                    <option value="D">D</option>
                    <option value="E">E</option>
                  </select>
                  <small>Baca tentang pedoman perjenjangan disini <a href="https://buku.kemdikbud.go.id/s/PedomanPerjenjangan" target="_blank">https://buku.kemdikbud.go.id/s/PedomanPerjenjangan</a> dan Perkabadan <a href="https://drive.google.com/file/d/19gDkCNt7kE-FLX9Xzj3ehuu4rzFfWJNF/view">disini</a></small>
                </div>
                <div class="form-group">
                  <label for="note" class="form-label">Kondisi Naskah</label>
                  <textarea v-model="register.note" id="note" rows="5" class="form-control" placeholder="Naskah / Buku Jadi" required></textarea>
                  <small>Masukan kondisi Naskah</small>
                </div>
                <div class="form-group">
                  <label for="role" class="form-label">Difabel Status</label>
                  <div class="form-check">
                    <input
                      v-model="register.difable_status"
                      value="yes"
                      class="form-check-input"
                      type="checkbox"
                      id="difable_status"
                      @click="setDifableStatus()"
                    />
                    <label class="form-check-label" for="difable_status">
                      Ya
                    </label>
                  </div>
                </div>
                <div v-if="policy.event_type == 'hybrid'" class="form-group">
                  <label for="eventType" class="form-label"
                    >Mengikuti Event secara :</label
                  >
                  <div class="form-check">
                    <input
                      v-model="register.session"
                      value="online"
                      class="form-check-input"
                      type="radio"
                      name="eventType"
                      id="online"
                    />
                    <label class="form-check-label" for="online">
                      Online (Zoom)
                    </label>
                  </div>
                  <div class="form-check">
                    <input
                      v-model="register.session"
                      value="offline"
                      class="form-check-input"
                      type="radio"
                      id="offline"
                      name="eventType"
                    />
                    <label class="form-check-label" for="offline">
                      Offline (di lokasi)
                    </label>
                  </div>
                </div>
                <div class="form-group">
                  <label for="profile_picture" class="form-label">
                    Foto Profil
                  </label>
                  <small class="d-block">Silakan unggah foto profil terbaik dalam format jpg atau png (maksimal 10mb).</small>
                  <small
                    v-if="message.profile_picture.error != ''"
                    class="text-danger d-block"
                  >
                    {{ message.profile_picture.error }}
                  </small
                  >
                  <input
                    :type="rules.profile_picture.form"
                    id="profile_picture"
                    class="form-control"
                    @change="selectFilePDF($event, 'profile_picture')"
                    required
                  />
                  <input type="hidden" v-model="fileUploaded.profile_picture" />
                  <div class="my-2">
                    <div class="row">
                      <div class="col-md-4">
                        <button
                          v-if="
                            !loading.profile_picture && !message.profile_picture.uploaded
                          "
                          @click="uploadFilePDF('profile_picture')"
                          type="button"
                          class="btn btn-sm btn-primary btn-block"
                        >
                          Upload
                        </button>
                        <div v-if="message.profile_picture.uploaded" class="d-flex">
                          <button
                            type="button"
                            class="btn btn-secondary btn-sm"
                          >
                            Uploaded
                          </button>
                          <span class="mt-1 ml-2">{{ fileUploaded.profile_picture }}</span>
                        </div>
                        <button
                          type="button"
                          v-if="loading.profile_picture && !message.profile_picture.uploaded"
                          class="btn btn-sm btn-primary btn-block"
                        >
                          <span class="spinner-border spinner-border-sm"></span>
                          Sedang upload..
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <label for="surat_pernyataan_keaslian_naskah" class="form-label">
                    Surat Pernyataan Keaslian Naskah
                  </label>
                  <small class="d-block">Silakan unggah surat pernyataan dalam 1 file pdf (maksimal 10mb). Contoh surat dapat diakses pada tautan <a target="_blank" href="https://buku.kemdikbud.go.id/s/SuratKeaslianKarya">https://buku.kemdikbud.go.id/s/SuratKeaslianKarya</a></small>
                  <small
                    v-if="message.surat_pernyataan_keaslian_naskah.error != ''"
                    class="text-danger d-block"
                  >
                    {{ message.surat_pernyataan_keaslian_naskah.error }}
                  </small
                  >
                  <input
                    :type="rules.surat_pernyataan_keaslian_naskah.form"
                    id="surat_pernyataan_keaslian_naskah"
                    class="form-control"
                    @change="selectFilePDF($event, 'surat_pernyataan_keaslian_naskah')"
                    required
                  />
                  <input type="hidden" v-model="fileUploaded.surat_pernyataan_keaslian_naskah" />
                  <div class="my-2">
                    <div class="row">
                      <div class="col-md-4">
                        <button
                          v-if="
                            !loading.surat_pernyataan_keaslian_naskah && !message.surat_pernyataan_keaslian_naskah.uploaded
                          "
                          @click="uploadFilePDF('surat_pernyataan_keaslian_naskah')"
                          type="button"
                          class="btn btn-sm btn-primary btn-block"
                        >
                          Upload
                        </button>
                        <div v-if="message.surat_pernyataan_keaslian_naskah.uploaded" class="d-flex">
                          <button
                            type="button"
                            class="btn btn-secondary btn-sm"
                          >
                            Uploaded
                          </button>
                          <span class="mt-1 ml-2">{{ fileUploaded.surat_pernyataan_keaslian_naskah }}</span>
                        </div>
                        <button
                          type="button"
                          v-if="loading.surat_pernyataan_keaslian_naskah && !message.surat_pernyataan_keaslian_naskah.uploaded"
                          class="btn btn-sm btn-primary btn-block"
                        >
                          <span class="spinner-border spinner-border-sm"></span>
                          Sedang upload..
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <label for="lampiran" class="form-label">
                    Lampiran
                  </label>
                  <small class="d-block">Silakan unggah lampiran naskah atau buku dalam format pdf (maksimal 10mb)</small>
                  <small
                    v-if="message.lampiran.error != ''"
                    class="text-danger d-block"
                  >
                    {{ message.lampiran.error }}
                  </small>
                  <input
                    :type="rules.lampiran.form"
                    id="lampiran"
                    class="form-control"
                    @change="selectFilePDF($event, 'lampiran')"
                    required
                  />
                  <input type="hidden" v-model="fileUploaded.lampiran" />
                  <div class="my-2">
                    <div class="row">
                      <div class="col-md-4">
                        <button
                          v-if="!loading.lampiran && !message.lampiran.uploaded"
                          @click="uploadFilePDF('lampiran')"
                          type="button"
                          class="btn btn-sm btn-primary btn-block"
                        >
                          Upload
                        </button>
                        <div v-if="message.lampiran.uploaded" class="d-flex">
                          <button
                            type="button"
                            class="btn btn-secondary btn-sm"
                          >
                            Uploaded
                          </button>
                          <span class="mt-1 ml-2">{{ fileUploaded.lampiran }}</span>
                        </div>
                        <button
                          type="button"
                          v-if="loading.lampiran && !message.lampiran.uploaded"
                          class="btn btn-sm btn-primary btn-block"
                        >
                          <span class="spinner-border spinner-border-sm"></span>
                          Sedang upload..
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="small"><b>Penting : </b>Jangan lupa klik tombol upload berkas sebelum daftar</div>
              </div>
              <div class="modal-footer">
                <button
                  v-if="!$store.state.loadProcess"
                  type="submit"
                  class="btn btn-primary"
                >
                  Daftar
                </button>
                <button v-else type="button" class="btn btn-primary">
                  Sedang diproses..
                </button>
              </div>
            </form>
            <div v-else class="modal-body">
              <div class="alert alert-success">
                Selamat, pendaftaran berhasil. Selanjutnya mohon untuk menunggu
                proses verifikasi.
              </div>

              <table class="table table-bordered">
                <tr>
                  <td width="160">Nama</td>
                  <td width="20">:</td>
                  <td>{{ dataRegistered.name }}</td>
                </tr>
                <tr>
                  <td>Nomor Tiket</td>
                  <td>:</td>
                  <td>{{ dataRegistered.ticketcode }}</td>
                </tr>
                <tr>
                  <td>Tanggal Daftar</td>
                  <td>:</td>
                  <td>{{ registeredDate }}</td>
                </tr>
                <tr>
                  <td>Kode QR</td>
                  <td>:</td>
                  <td>
                    <img :src="qrCodeRegistered" alt="" />
                  </td>
                </tr>
              </table>

              <p>
                <span style="color:red;">
                  Note : Mohon untuk menyimpan nomor tiket pendaftaran untuk
                  keperluan verifikasi. Bukti pendaftaran acara juga sudah kami
                  kirimkan ke email
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <!-- End Register Modal -->

      <!-- Tiket Modal -->
      <div
        class="modal fade"
        id="tiketModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="tiketModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title text-center" id="tiketModalLabel">
                Tiket
              </h5>
              <button
                @click="$store.state.messageRegisterSuccess = ''"
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <p>Berikut ini informasi tiket anda :</p>
              <table class="table table-bordered">
                <tr>
                  <td width="160">Nama</td>
                  <td width="20">:</td>
                  <td>{{ userRegisteredStatus.name }}</td>
                </tr>
                <tr>
                  <td>Nomor Tiket</td>
                  <td>:</td>
                  <td>{{ userRegisteredStatus.ticketcode }}</td>
                </tr>
                <tr>
                  <td>Tanggal Daftar</td>
                  <td>:</td>
                  <td>{{ registeredDate }}</td>
                </tr>
                <tr>
                  <td>Kode QR</td>
                  <td>:</td>
                  <td>
                    <img :src="qrCode" alt="" />
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
      <!-- End Tiket Modal -->

      <!-- Check Kepesertaan Modal -->
      <div
        class="modal fade"
        id="checkKepesertaan"
        tabindex="-1"
        role="dialog"
        aria-labelledby="checkKepesertaanLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-lg" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title text-center" id="checkKepesertaanLabel">
                Cek Kepesertaan
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <p>Masukan email untuk mengecek data berkas dan kepesertaan Anda.</p>
              <div class="input-group mb-3">
                <input @keyup.enter="checkUserStatus()" v-model="userStatus.email" type="text" class="form-control" placeholder="Cth: titin@gmail.com" required>
                <div class="input-group-prepend">
                  <button @click="checkUserStatus()" class="btn btn-outline-secondary" type="button" style="border-radius: 0 !important;">Cek Sekarang</button>
                </div>
              </div>
              <div v-show="userStatus.loading" class="text-center py-3">
                <div class="spinner-border" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
              <div v-show="!userStatus.loading && userStatus.message && Object.keys(userStatus.data).length == 0" class="text-center py-2 alert alert-warning">
                <div>{{ userStatus.message }}</div>
              </div>
              <table v-show="Object.keys(userStatus.data).length > 0" class="table table-bordered">
                <tr>
                  <td width="160">Nama</td>
                  <td width="20">:</td>
                  <td>{{ userStatus.data.name }}</td>
                </tr>
                <tr>
                  <td>Status</td>
                  <td>:</td>
                  <td>
                    <div v-if="userStatus.data.status == 'pending'">
                      Pending (Sedang dalam verifikasi lampiran)
                    </div>
                    <div v-else>
                      {{ userStatus.data.status }}
                    </div>
                  </td>
                </tr>
                <!-- <tr>
                  <td>Kode Tiket</td>
                  <td>:</td>
                  <td>{{ userStatus.data.ticketcode }}</td>
                </tr>
                <tr>
                  <td>Foto Profil</td>
                  <td>:</td>
                  <td>
                    <div v-if="userStatus.file.profilePicture">
                      ✅ Berhasil Diunggah ({{ userStatus.file.profilePicture }})
                    </div>
                    <div v-else>
                      ❌ Belum terupload
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>Lampiran</td>
                  <td>:</td>
                  <td>
                    <div v-if="userStatus.file.lampiran">
                      ✅ Berhasil Diunggah ({{ userStatus.file.lampiran }})
                    </div>
                    <div v-else>
                      ❌ Belum terupload
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>Surat Pernyataan Keaslian Naskah</td>
                  <td>:</td>
                  <td>
                    <div v-if="userStatus.file.suratPernyataanKeaslianNaskah">
                      ✅ Berhasil Diunggah ({{ userStatus.file.suratPernyataanKeaslianNaskah }})
                    </div>
                    <div v-else>
                      ❌ Belum terupload
                    </div>
                  </td>
                </tr> -->
              </table>
            </div>
          </div>
        </div>
      </div>
      <!-- End Check Kepesertaan Modal -->
    </section>
  </div>
</template>

<style scoped>
.icon-wrapper {
  background: var(--grey-200);
  padding: 0.5rem;
  border-radius: 0.5rem;
  align-self: flex-start;
  margin-right: 0.75rem;
}
</style>

<script>
import { mapActions, mapState } from "vuex";
import moment from "moment";
import axios from "axios";
import { BASE_URL } from "../../../../store";
import SvgIcon from "@/components/common/SvgIcon.vue";
import Banner from "@/components/homepage/Banner.vue";
import Swal from "sweetalert2";

moment.locale("id");

export default {
  components: { SvgIcon, Banner },
  data() {
    return {
      isLoggedIn: false,
      userStatus: {
        email: '',
        loading: false,
        message: '',
        file: {
          profilePicture: '',
          portfolio: '',
          lampiran: '',
          suratPernyataanKeaslianNaskah: ''
        },
        data: {}
      },
      openLink: false,
      readTerms: false,
      category: 'Fiksi',
      theme: 'Literasi Digital',
      optionMore: '',
      breadcrumbs: [
        {
          label: "Beranda",
          url: "/",
        },
        {
          label: "Pembinaan",
          url: "/pelaku/pembinaan",
        },
        {
          label: "Detil Pembinaan",
          url: "",
        },
      ],
      register: {
        training_id: "",
        user_id: "",
        name: "",
        email: "",
        phone: "",
        address: "",
        difable_status: false,
        session: "offline",
        studentMeta: "",
        role: 'Buku Bergambar',
        note: "-",
        role_detail: "A",
      },
      file: {
        profile_picture: null,
        portfolio: null,
        lampiran: null,
        surat_pernyataan_keaslian_naskah: null,
      },
      fileUploaded: {
        profile_picture: "",
        portfolio: "",
        lampiran: "",
        surat_pernyataan_keaslian_naskah: "",
      },
      message: {
        profile_picture: {
          error: "",
          uploaded: false,
        },
        portfolio: {
          error: "",
          uploaded: false,
        },
        lampiran: {
          error: "",
          uploaded: false,
        },
        surat_pernyataan_keaslian_naskah: {
          error: "",
          uploaded: false,
        },
      },
      loading: {
        profile_picture: false,
        portfolio: false,
        lampiran: false,
        surat_pernyataan_keaslian_naskah: false,
      },
      rules: {
        profile_picture: {
          label: "Profile Picture",
          form: "file",
          required: true,
        },
        portfolio: {
          label: "Portofolio",
          form: "file",
          required: true,
        },
        lampiran: {
          label: "Lampiran",
          form: "file",
          required: true,
        },
        surat_pernyataan_keaslian_naskah: {
          label: "Surat Pernyatan Keaslian Naskah",
          form: "file",
          required: true,
        },
      },
      dataRegistered: [],
      userRegisteredStatus: [],
      successRegistered: false,
    };
  },
  computed: {
    ...mapState(["policy"]),
    modalForm: function() {
      let targetModal = '#registerModal';
      if(this.register.training_id == 25) targetModal = '#registerModalPenulis'
      if(this.register.training_id == 27) targetModal = '#registerModalIlustrator'
      if(this.register.training_id == 36 || this.register.training_id == 37 || this.register.training_id == 38) targetModal = '#registerModalSimple'
      return targetModal
    },
    remainingQuota: function() {
      let calculate = this.policy.seats - this.policy.registered
      return calculate > 0 ? calculate : 0
    },
    convertDateTitle: function() {
      return moment(this.policy.start).format("LL");
    },
    convertStartDate: function() {
      return moment(this.policy.start).format("LL");
    },
    convertEndDate: function() {
      return moment(this.policy.end).format("LL");
    },
    registeredDate: function() {
      return moment(this.userRegisteredStatus.created_at).format("LL");
    },
    qrCode: function() {
      let link = `https://app.buku.kemdikbud.go.id/ticket/${this.userRegisteredStatus.ticketcode}`;
      return `https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=${link}`;
    },
    qrCodeRegistered: function() {
      let link = `https://app.buku.kemdikbud.go.id/ticket/${this.dataRegistered.ticketcode}`;
      return `https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=${link}`;
    },
    dateRegistered: function() {
      return moment(this.dataRegistered.created_at).format("LL");
    },
  },
  methods: {
    ...mapActions([
      "fetchDetailTraining",
      "uploadFile",
      "registerTraining",
      "fetchRegisterTraining",
    ]),
    attend(ticketcode) {
      // Submit with form data
      const formData = new FormData();
      formData.append("ticketcode", ticketcode);

      Swal.fire({
        title: 'Perhatian',
        icon: 'warning',
        text: 'Konfirmasi kehadiran pelaksanaan kegiatan ini?',
        confirmButtonText: 'Sudah, lanjutkan kehadiran',
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .post(`${BASE_URL}api/training/checkin/`, formData)
            .then((res) => {
              if (res.data.status == "success") {
                Swal.fire({
                  title: 'Berhasil',
                  icon: 'success',
                  text: 'Terima kasih sudah hadir dalam kegiatan ini. Untuk mengapresiasi kehadiran Bapak/Ibu, Kami sudah menerbitkan sertifikat pada tautan dibawah ini',
                  showConfirmButton: false,
                  showCloseButton: true,
                  footer: `<a target="_blank" href="https://app.buku.kemdikbud.go.id/sertifikat/${ticketcode}">Lihat Sertifikat</a>`
                })
              }
            })
        }
      })
    },
    setTypeBook(type) {
      this.register.role = type

      // Set default role detail
      if(type == 'Cerita Bergambar (Fiksi)') {
        this.register.role_detail = 'A'
        this.theme = 'Literasi Digital'
      } 
      if(type == 'Nonfiksi Naratif') {
        this.register.role_detail = 'A'
        this.theme = 'Antiperundungan'
      } 
      if(type == 'Puisi (Kumpulan Puisi)') {
        this.register.role_detail = 'C'
        this.theme = 'Literasi Digital'
      }
      if(type == 'Komik') {
        this.register.role_detail = 'D'
        this.theme = 'Literasi Finansial'
      } 
      if(type == 'Novel') {
        this.register.role_detail = 'D'
        this.theme = 'Nutrisi dan Kebugaran'
      }      
    },
    setLevelBook(level) {
      this.register.role_detail = level

      // Set default theme
      let typeBook = this.register.role
      if(typeBook == 'Cerita Bergambar (Fiksi)') {
        this.theme = 'Literasi Digital'
      } 
      if(typeBook == 'Nonfiksi Naratif') {
        this.theme = 'Literasi Digital'
      } 
      if(typeBook == 'Puisi (Kumpulan Puisi)') {
        this.theme = 'Literasi Digital'
      }
      if(typeBook == 'Komik') {
        this.theme = 'Literasi Finansial'
      } 
      if(typeBook == 'Novel') {
        this.theme = 'Nutrisi dan Kebugaran'
      }
    },
    setThemeBook(theme) {
      this.theme = theme
    },
    setDefaultCategory(type) {
      if(this.register.training_id == 25) {
        this.register.role = type == 'Fiksi' ? 'Cerita Bergambar' : 'Ensiklopedi'
      } else {
        this.register.role = type == 'Fiksi' ? 'Buku Bergambar' : 'Ensiklopedi'
      }
    },
    setDifableStatus() {
      this.register.difable_status = !this.register.difable_status;
    },
    selectFilePDF(e, state) {
      const file = e.target.files[0];
      this.file[state] = file;
    },
    uploadFilePDF(state) {
      // Check file size before upload
      if (this.file[state] == null) {
        this.message[state].error = "File harus diisi";
      } else {
        if (this.file[state].size >= 10000000) {
          this.message[state].error =
            "Ukuran file terlalu besar, maksimal 10 mb";
        } else {
          this.loading[state] = true;
          this.message[state].error = "";
          this.uploadFile(this.file[state])
            .then((res) => {
              if (res.status === "success") {
                this.message[state].uploaded = true;
                this.fileUploaded[state] = res.file;
              } else {
                this.message[state].error = res.message;
              }
            })
            .catch((err) => {
              this.message[state].error = "File gagal diupload";
              console.log(err);
            })
            .finally(() => {
              this.loading[state] = false;
            });
        }
      }
    },
    checkUserStatus() {
      if(this.userStatus.email) {
        // Reset state
        this.userStatus.message = ''
        this.userStatus.file = {
          profilePicture: '',
          lampiran: '',
          suratPernyataanKeaslianNaskah: ''
        },
        this.userStatus.data = {}

        this.userStatus.loading = true
        axios
          .get(`${BASE_URL}api/training/check?email=${this.userStatus.email}&training_id=${this.$route.query.id}`)
          .then((response) => {
            if(response.data.status == 'success') {
              this.userStatus.data = response.data.data

              // Parsing student meta
              const studentMeta = JSON.parse(response.data.data.studentmeta)
              this.userStatus.file.profilePicture = studentMeta.profile_picture
              this.userStatus.file.lampiran = studentMeta.lampiran
              this.userStatus.file.suratPernyataanKeaslianNaskah = studentMeta.surat_pernyataan_keaslian_naskah
            } else {
              this.userStatus.message = response.data.message
            }
            this.userStatus.loading = false
          })
      } else {
        Swal.fire({
          icon: "warning",
          title: `Silahkan isi email terlebih dahulu`,
        })
        return;
      }
    },
    postRegisterTraining() {

      // Check jika role == 'Naskah Lainnya'
      if(this.register.role == 'Naskah Lainnya') {
        if(this.optionMore) {
          this.register.role == this.optionMore
        } else {
          Swal.fire({
            icon: "warning",
            title: `Silahkan isi kategori lainnya terlebih dahulu`,
          })
          return;
        }
      }

      // Check validasi file sudah diupload atau belum untuk training_id == 23
      if(this.register.training_id == 23) {
        if(!this.fileUploaded.profile_picture || !this.fileUploaded.lampiran || !this.fileUploaded.surat_pernyataan_keaslian_naskah) {
          Swal.fire({
            icon: "warning",
            title: `Silahkan klik tombol upload pada setiap berkas terlebih dahulu`,
          })
          return;
        }
      }

      // Check validasi file sudah diupload atau belum untuk training_id == 25 dan training_id == 27
      if(this.register.training_id == 25 || this.register.training_id == 27) {
        if(!this.fileUploaded.profile_picture || !this.fileUploaded.lampiran || !this.fileUploaded.portfolio) {
          Swal.fire({
            icon: "warning",
            title: `Silahkan klik tombol upload pada setiap berkas terlebih dahulu`,
          })
          return;
        }
      } 

      const files = {};

      // Inject file yang dibutuhkan pada event training_id 23
      if(this.register.training_id == 23) {
        files.profile_picture = this.fileUploaded.profile_picture
        files.lampiran = this.fileUploaded.lampiran
        files.surat_pernyataan_keaslian_naskah = this.fileUploaded.surat_pernyataan_keaslian_naskah
      }

      // Inject file yang dibutuhkan pada event training_id 25
      if(this.register.training_id == 25) {
        files.profile_picture = this.fileUploaded.profile_picture
        files.lampiran = this.fileUploaded.lampiran
        files.portfolio = this.fileUploaded.portfolio
        this.register.theme = this.theme
      }

      // Inject file yang dibutuhkan pada event training_id 27
      if(this.register.training_id == 27) {
        files.profile_picture = this.fileUploaded.profile_picture
        files.lampiran = this.fileUploaded.lampiran
        files.portfolio = this.fileUploaded.portfolio
        this.register.theme = '-'
      }

      // Merge all file to field student meta and stringify
      this.register.studentMeta = JSON.stringify(files);
      console.log(this.register)

      this.registerTraining(this.register).then((res) => {
        if (res.data.status == "success") {
          this.dataRegistered = res.data.data;
          this.successRegistered = true;
          this.userRegisteredStatus = "pending";
        } else {
          this.successRegistered = false;
        }
      });
    },
    setValue(data) {
      this.register.user_id = data.user_id;
      this.register.name = data.fullname;
      this.register.email = data.email;
    },
  },
  created() {
    const token = localStorage.getItem("token");
    if (token) {
      this.isLoggedIn = true;

      const user = JSON.parse(localStorage.getItem("user"));

      // Set default value from localstorage
      this.setValue(user)

      const data = {
        user_id: user.user_id,
        training_id: this.$route.query.id,
      };
      
      this.fetchRegisterTraining(data).then((res) => {
        this.userRegisteredStatus = res.data;
      });
    }

    this.register.training_id = this.$route.query.id;
    this.fetchDetailTraining(this.$route.query.id);

    // Check if event id == 25 & 27 set default value role
    if(this.$route.query.id == 25) {
      this.register.role = 'Cerita Bergambar (Fiksi)'
    }
    if(this.$route.query.id == 27) {
      this.register.role = 'Cerita Bergambar (Fiksi)'
      this.register.role_detail = '-'
    }
  },
};
</script>
